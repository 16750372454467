var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"140vcCmBLJIzfudnVAYzR"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import * as Sentry from '@sentry/nextjs';
import { NODE_ENV, SENTRY_DSN } from 'globalConstants';

Sentry.init({
  environment: NODE_ENV,
  dsn: SENTRY_DSN,
  tracesSampleRate: 1.0,
});
