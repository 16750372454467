export const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;
export const NODE_ENV = process.env.NEXT_PUBLIC_NODE_ENV;
export const BASE_PATH = process.env.NEXT_PUBLIC_BASE_PATH ?? 'localhost';
export const MAIN_DOMAIN = process.env.NEXT_PUBLIC_MAIN_DOMAIN ?? 'http://localhost:4200';
export const SOCIAL_DOMAIN = process.env.NEXT_PUBLIC_SOCIAL_DOMAIN ?? 'http://localhost:4201';
export const ACCOUNT_DOMAIN = process.env.NEXT_PUBLIC_ACCOUNT_DOMAIN ?? 'http://localhost:4202';
export const API_ENDPOINT1 = process.env.NEXT_PUBLIC_API_ENDPOINT1 ?? 'http://localhost:8400';
export const API_ENDPOINT2 = process.env.NEXT_PUBLIC_API_ENDPOINT2 ?? 'http://localhost:8400';
export const SOCKET_API_ENDPOINT =
  process.env.NEXT_PUBLIC_SOCKET_API_ENDPOINT ?? 'http://localhost:8401';
export const MAP_API_ENDPOINT = process.env.NEXT_PUBLIC_MAP_API_ENDPOINT ?? 'http://localhost:8402';
export const BLOG_API_ENDPOINT =
  process.env.NEXT_PUBLIC_BLOG_API_ENDPOINT ?? 'http://localhost:8403';
export const ZALO_OA_ID = process.env.NEXT_PUBLIC_ZALO_OA_ID ?? '';
export const FACEBOOK_OA_ID = process.env.NEXT_PUBLIC_FACEBOOK_OA_ID ?? '';
export const GOOGLE_CLIENT_ID = process.env.NEXT_PUBLIC_GOOGLE_CLIENT_ID ?? '';
export const GOOGLE_CLIENT_SECRET = process.env.NEXT_PUBLIC_GOOGLE_CLIENT_SECRET ?? '';
export const ANALYTICS_ID = process.env.NEXT_PUBLIC_ANALYTICS_ID ?? '';
export const DEFAULT_LOCALE = 'vi';
export const DEFAULT_TITLE = 'ATM NHÀ - Sàn thương mại điện tử cung cấp dịch vụ bất động sản';
export const DEFAULT_SHORT_DESCRIPTION =
  'ATM NHÀ chuyên cung cấp các dịch vụ bất động sản với giá hợp lý như đăng tin bất động sản, định giá bất động sản, tra cứu bản đồ quy hoạch, mạng xã hội bất động sản';
export const DEFAULT_DESCRIPTION = `ATM NHÀ chuyên cung cấp các dịch vụ bất động sản với giá hợp lý như đăng tin bất động sản, định giá bất động sản, tra cứu bản đồ quy hoạch, mạng xã hội bất động sản, tìm kiếm và ký gửi dự án, hưởng hoa hồng từ mạng lưới người dùng. Website: ${MAIN_DOMAIN}`;
export const DEFAULT_OG_IMAGE = '/images/facebook-share-thumbnail.webp';
export const DEFAULT_INIT_STATIC_PAGE = 1000;
export const DEFAULT_CONTACT_PHONE = '0789333322';
export const DEFAULT_SUPPORT_EMAIL = 'contact@atmnha.vn';
export const DEFAULT_MAIN_SEARCH_TOOL_PLACEHOLDER =
  'Nhập địa điểm hoặc từ khoá (Ví dụ: Vinhomes hoặc 138 Lý Thường Kiệt hoặc số điện thoại người đ...)';
export const DEFAULT_DISCOVERY_SEARCH_TOOL_PLACEHOLDER = 'Tìm kiếm dịch vụ';
export const DEFAULT_PROJECT_SEARCH_TOOL_PLACEHOLDER = 'Tìm kiếm dự án...';
export const NAME_REGEX = /^[A-Za-zÀ-ỹ\s]+$/;
export const MOBILE_DEVICE_REGEX =
  /Android|BlackBerry|iPhone|iPad|iPod|Opera Mini|IEMobile|WPDesktop/i;
export const PHONE_NUMBER_REGEX = /^0([35789])[0-9]{8}$/;
export const VIETNAMESE_ID_NUMBER_REGEX = /^$|\d{9}|\d{12}$/;
export const YOUTUBE_LINK_REGEX = /^(https:\/\/www\.youtube\.com\/watch\?v=[a-zA-Z0-9_-]+)?$/;
export const TAGLINE = 'Sàn thương mại điện tử cung cấp dịch vụ bất động sản';
export const COMPANY_NAME = 'CÔNG TY CỔ PHẦN CÔNG NGHỆ KỲ LÂN ZMS';
export const FOUNDER_NAME = 'Võ Văn Tính';
export const BRANCH_NAME = 'ATM NHÀ';
export const DEFAULT_ALTERNATE_NAME = 'atmnha.vn';
export const DEFAULT_COMPANY_DESCRIPTION =
  'CÔNG TY CỔ PHẦN CÔNG NGHỆ KỲ LÂN ZMS là công ty công nghệ hoạt động về lĩnh vực Thương mại điện tử (TMĐT) Bất động sản. Cung cấp nền tảng trực tuyến để khách hàng có thể tìm kiếm, trao đổi những thông tin về các loại hình nhà, đất thông qua các chức năng đăng tin đối với người bán hoặc Tìm kiếm đối với người mua. Được bắt tay vào nghiên cứu & chính thức chạy thử nghiệm phiên bản đầu tiên vào năm 2018 nhằm đánh giá đúng nhu cầu thị trường, ghi nhận phải hồi từ khách hàng và tối ưu trải nghiệm khách hàng trong quá trình sử dụng với phiên bản ATM NHÀ.';
export const DEFAULT_ADDRESS = '647 Lý Thường Kiệt, phường 11, quân Tân Bình, TPHCM, Việt Nam';
export const DEFAULT_ADDRESS_LOCALITY = 'Thành phố Hồ Chí Minh';
export const DEFAULT_COUNTRY = 'Việt Nam';
export const DEFAULT_EMAIL_ADDRESS = 'contact@atmnha.vn';
export const DEFAULT_ZIP_CODE = '700000';
export const DEFAULT_SOCIAL_MEDIA_LINKS = [
  'https://www.facebook.com/atmnha',
  'https://www.youtube.com/channel/UCawniMq-oiML35r6E7aNAFQ',
  'https://www.tiktok.com/@atmnha',
  'https://www.pinterest.com/atmnhavn/',
  'https://sites.google.com/view/httpsatmnhavn/trang-ch%E1%BB%A7?authuser=1',
  'https://www.tumblr.com/amtnha',
];
